import $ from "jquery"

$(function(){
  $('[data-controller="storage-stats"]').on("click", function(event) {
    event.preventDefault();
    $.ajax({
      url: event.target.parentElement.parentElement.dataset.url,
      success: function(response){
        $('#modal-body').html(response);
        $('#statistics_modal').modal('show');
      }
    })
  })
})
