import $ from "jquery"

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el, controller_name) {
  var splitted_url = window.location.href.split("/");

  var image_search_id = ""
  if (controller_name == "slugged_searches") {
    image_search_id = splitted_url[splitted_url.indexOf("slugged_searches") + 1]
  } else {
    image_search_id = splitted_url[splitted_url.indexOf("storages") + 1]
  }

  var url = window.location.protocol + "//" + window.location.host + "/api/v1/images/" + controller_name + "/" + image_search_id;
  $.ajax({
    url: url,
    method: "delete",
    data: { image_filename: el.name },
    success: function() {
      el.previewTemplate.remove();
    }
  })

}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}
