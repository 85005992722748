import "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

Turbolinks.start();
ActiveStorage.start();

import "bootstrap/dist/js/bootstrap"
import "./google-maps-api"
import "./theme/theme"
import "./search_form_autocomplete"
import "./alert"
import "./validation"
import "./load_datatable"
import "./magnific-popup"

import "cocoon-js"
import "./../controllers"


// needed to use jquery in the rails ujs responses
import $ from 'jquery'

window.$ = $;

import CookiesEuBanner from "cookies-eu-banner"
document.addEventListener("turbolinks:load", () => {
  new CookiesEuBanner(function () {
  // Code to launch when user accept cookies
    console.log("Cookies Consent accepted")
  });
})
